import axios from "axios";

const sendForm = async (data) => {
  callTouch(data.phone);
  const formEmail = toFormData(data);
  const response = await axios.post("https://parnas-hyundai.ru/message.php", formEmail);
  return response;
};

const toFormData = (obj) => {
  const formData = new FormData();
  for (const key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};

const callTouch = (phone) => {
  try {
    let phone_ct = phone.replace(/[^0-9]/gim, "");
    window.ctw.createRequest("parnas-hyundai", phone_ct, [], function (success, data) {
      console.log(success, data);
    });
  } catch (e) {
    console.log(e);
  }
};

export default sendForm;

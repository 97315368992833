<template>
  <div class="modal__wrapper modal__wrapper_low" id="car-modal" @click.self="$emit('close')">
    <div class="modal">
      <p class="text_blue text_uppercase text_big text_bold modal__title">{{ car.title }}</p>
      <img class="modal__close" src="@/assets/icons/close.svg" alt="X" @click="$emit('close')" />
      <img class="modal__img" :src="require(`@/assets/images/cars/${car.shortTitle}/${currentColor.img}.png`)" alt="car" />
      <div class="modal__tab-wrapper">
        <div
          @click="changeTab('color', 'Цвет')"
          class="modal__tab modal__tab_not-mobile"
          :class="{ modal__tab_active: currentTab === 'color' }"
        >
          Цвет
        </div>
        <div
          @click="changeTab('mod', 'Модификации')"
          class="modal__tab modal__tab_not-mobile"
          :class="{ modal__tab_active: currentTab === 'mod' }"
        >
          Модификации
        </div>
        <div
          @click="changeTab('set', 'Комплектации')"
          class="modal__tab modal__tab_not-mobile"
          :class="{ modal__tab_active: currentTab === 'set' }"
        >
          Комплектации
        </div>
        <button class="select mobile" @click="openSelect = !openSelect">
          {{ selectTitle }}
          <span class="select-arrow"></span>
        </button>
        <transition name="slide-menu-bottom">
          <div v-show="openSelect" class="select__block">
            <div @click="changeTab('color', 'Цвет')" class="modal__tab" :class="{ modal__tab_active: currentTab === 'color' }">Цвет</div>
            <div @click="changeTab('mod', 'Модификации')" class="modal__tab" :class="{ modal__tab_active: currentTab === 'mod' }">
              Модификации
            </div>
            <div @click="changeTab('set', 'Комплектации')" class="modal__tab" :class="{ modal__tab_active: currentTab === 'set' }">
              Комплектации
            </div>
          </div>
        </transition>
      </div>
      <div v-show="currentTab === 'color'">
        <p class="text_grey text_bold text_middle-2 offset_40">{{ currentColor.title }}</p>
        <div class="modal__color-wrapper">
          <div
            v-for="color in car.colors"
            :key="color.id"
            :style="{ backgroundColor: color.item }"
            class="modal__color"
            :class="{ modal__color_active: currentColor.id === color.id }"
            @click="changeColor(color)"
          ></div>
        </div>
      </div>
      <div v-show="currentTab === 'mod'" class="offset_40">
        <transition-group
          tag="div"
          :name="back ? 'slideback' : 'slide'"
          class="slider__wrapper"
          :class="{ slider__wrapper_higher: car.mods.length > 2 }"
        >
          <div class="slider" v-for="i in [currentIndex]" :key="`md-${i}`">
            <div v-for="mod in mods[i]" :key="mod.id">
              <p class="text_grey text_bold text_middle-2 text_line-height-130" v-html="mod.title"></p>
              <p class="text_grey text_regular text_line-height-130" v-html="mod.subtitle"></p>
            </div>
          </div>
        </transition-group>
        <div class="slider-control" v-if="this.mods.length > 1">
          <div
            class="slider-control__button slider-control__button_left"
            :class="{ 'slider-control__button_active': this.currentIndex !== 0 }"
            @click="prev"
          ></div>
          <div class="slider-control__indicators">
            <div
              v-for="i in this.mods.length"
              :key="i"
              class="slider-control__indicators-item"
              :class="{ 'slider-control__indicators-item_active': i - 1 === currentIndex }"
            ></div>
          </div>
          <div
            class="slider-control__button slider-control__button_right"
            :class="{ 'slider-control__button_active': this.currentIndex !== this.mods.length - 1 }"
            @click="next(mods)"
          ></div>
        </div>
      </div>
      <div v-show="currentTab === 'set'" class="offset_40">
        <transition-group
          tag="div"
          :name="back ? 'slideback' : 'slide'"
          class="slider__wrapper"
          :class="{ slider__wrapper_higher: car.sets.length > 2 }"
        >
          <div class="slider" v-for="i in [currentIndex]" :key="`md-${i}`">
            <div v-for="set in sets[i]" :key="set.id">
              <p class="text_grey text_bold text_middle-2 text_line-height-130" v-html="set.title"></p>
              <p class="text_grey text_regular text_small text_line-height-130" v-html="set.subtitle" style="margin-top: 5rem"></p>
            </div>
          </div>
        </transition-group>
        <div class="slider-control" :class="{ 'slider-control_offset': currentTab === 'set' }" v-if="this.sets.length > 1">
          <div
            class="slider-control__button slider-control__button_left"
            :class="{ 'slider-control__button_active': this.currentIndex !== 0 }"
            @click="prev"
          ></div>
          <div class="slider-control__indicators">
            <div
              v-for="i in this.sets.length"
              :key="i"
              class="slider-control__indicators-item"
              :class="{ 'slider-control__indicators-item_active': i - 1 === currentIndex }"
            ></div>
          </div>
          <div
            class="slider-control__button slider-control__button_right"
            :class="{ 'slider-control__button_active': this.currentIndex !== this.sets.length - 1 }"
            @click="next(sets)"
          ></div>
        </div>
      </div>
      <div class="modal__button-wrapper">
        <button @click="openForm(getOffer)" class="button modal__button">Получить предложение</button>
        <button @click="openForm(testDrive)" class="button button_blue-border modal__button">Пройти тест-драйв</button>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
export default {
  name: "Modal",
  props: {
    car: Object,
  },
  data() {
    return {
      currentTab: "color",
      currentColor: {},
      mods: [],
      sets: [],
      currentIndex: 0,
      back: false,
      openSelect: false,
      selectTitle: "Цвет",
      getOffer: {
        success: false,
        title: "Получить предложение",
        text: "Оставьте заявку, и получите специальное предложение.",
      },
      testDrive: {
        success: false,
        title: "Tест-драйв",
        text: "Оставьте заявку, и мы запишем Вас на тест-драйв в нашем центре.",
      },
    };
  },
  beforeMount() {
    this.car.colors.forEach((item) => {
      if (item.id === 2) {
        this.currentColor = item;
      }
    });
    this.initMods();
    this.initSets();
  },

  methods: {
    changeColor(color) {
      this.currentColor = color;
    },
    openForm(info) {
      eventBus.$emit("form", info);
    },
    initMods() {
      let size = 3;
      const blocks = [];
      if (window.innerWidth < 768) {
        size = 4;
      }
      for (let i = 0; i < Math.ceil(this.car.mods.length / size); i++) {
        blocks[i] = this.car.mods.slice(i * size, i * size + size);
        this.mods.push(blocks[i]);
      }
    },
    initSets() {
      let size = 3;
      const blocks = [];
      if (window.innerWidth < 768) {
        size = 4;
      }
      for (let i = 0; i < Math.ceil(this.car.sets.length / size); i++) {
        blocks[i] = this.car.sets.slice(i * size, i * size + size);
        this.sets.push(blocks[i]);
      }
    },
    changeTab(name, title) {
      this.currentTab = name;
      this.selectTitle = title;
      this.currentIndex = 0;
      this.openSelect = false;
    },
    next(arr) {
      if (this.currentIndex !== arr.length - 1) {
        this.back = false;
        this.currentIndex += 1;
      }
    },
    prev() {
      if (this.currentIndex !== 0) {
        this.back = true;
        this.currentIndex -= 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  max-height: 980rem;
  height: 95%;
  background: $lightGrey;
  padding: 40rem 134rem 80rem;
  &__img {
    height: 35%;
    max-height: 300rem;
    position: relative;
    left: 50%;
    top: -10px;
    transform: translateX(-50%);
  }
  &__tab {
    width: 254rem;
    color: $grey;
    background: $white;
    font-family: "Hyundai-Bold";
    font-size: 22rem;
    padding: 20rem;
    text-align: center;
    margin-right: 4rem;
    cursor: pointer;
    transition: 0.3s;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $white;
      background: $blue;
    }
    &_not-mobile {
      display: block;
    }
    &-wrapper {
      display: flex;
      margin-top: 20rem;
      position: relative;
    }
    &_active {
      background: $blue;
      color: $white;
    }
  }
  &__color {
    width: 56rem;
    height: 56rem;
    cursor: pointer;
    outline-offset: 4px;
    outline: 2px solid $white;
    &_active {
      outline: 2px solid #ef4123;
      outline-offset: 4px;
    }
    &-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 40rem;
    }
  }
  &__button {
    width: 270rem;
    &-wrapper {
      column-gap: 40px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      bottom: 80rem;
    }
  }
}
.slider {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 30px;
  justify-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &__wrapper {
    overflow: hidden;
    position: relative;
    height: 160rem;
  }
  &-control {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_offset {
      top: 30px;
    }
    &__button {
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      width: 42rem;
      height: 42rem;
      position: relative;
      transition: 0.3s;
      &_active {
        cursor: pointer;
        border-color: $blue;
      }
      &_left {
        left: 10rem;
        transform: rotate(132deg);
      }
      &_right {
        right: 10rem;
        transform: rotate(316deg);
      }
    }
    &__indicators {
      display: flex;
      &-item {
        width: 60rem;
        height: 4rem;
        background: $white;
        margin-right: 40rem;
        transition: 0.3s;
        &_active {
          background: $blue;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}

.slide-menu-bottom-leave-active,
.slide-menu-bottom-enter-active {
  transition: all 0.3s;
}
.slide-menu-bottom-enter,
.slide-menu-bottom-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@media (max-height: 920px) {
  .modal {
    height: 98%;
    padding: 40rem 80rem 60rem;
    &__tab {
      padding: 16rem;
      font-size: 22rem;
      &-wrapper {
        margin-top: 0;
      }
    }
    &__button-wrapper {
      bottom: 40rem;
    }
  }
  .offset_40 {
    margin-top: 20rem;
  }
  .slider {
    &-control__button {
      width: 24rem;
      height: 24rem;
    }
  }
}

@media (max-height: 600px) and (max-width: 767px) {
  .slider {
    &__wrapper {
      height: 200rem;
      &_higher {
        height: 420rem;
      }
    }
  }
}

@include adaptive(mob-big) {
  .modal {
    width: 95%;
    padding: 40rem 80rem 60rem;
    &__tab {
      width: 100%;
    }
    &__color {
      &-wrapper {
        justify-content: flex-start;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
      }
    }
  }
}

@include adaptive(mob) {
  .modal {
    padding: 60rem 40rem;
    height: auto;
    max-height: 100%;
    overflow: auto;
    &__title {
      text-align: center;
      margin-top: 30rem;
    }
    &__tab {
      padding: 20rem;
      border-bottom: 2px solid $blue;
      width: 100%;
      &:last-child {
        border-bottom: none;
      }
      &_not-mobile {
        display: none;
      }
    }
    &__img {
      width: 100%;
      margin-top: 20rem;
    }
    &__button {
      width: 100%;
      margin-bottom: 20rem;
      &:last-child {
        margin-bottom: 0;
      }
      &-wrapper {
        flex-direction: column;
        position: relative;
        left: 0;
        width: 100%;
        bottom: 0;
        transform: translateX(0);
        margin-top: 60rem;
      }
    }
  }
  .slider {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 15px;
    justify-items: flex-start;
    align-items: flex-start;
    &-control {
      &_offset {
        top: 0;
      }
    }
    &__wrapper_higher {
      height: 340rem;
    }
  }
}

@media (max-width: 360px) {
  .slider {
    &__wrapper {
      &_higher {
        height: 430rem;
      }
    }
  }
}
</style>

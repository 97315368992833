<template>
  <div class="map">
    <div class="map__info">
      <div class="map__info-group">
        <img class="map__info-icon" src="@/assets/icons/phone-white-icon.svg" alt="phone" />
        <a href="tel:+78123378888" class="text_white text_middle text_bold">+7 (812) 337-88-88</a>
      </div>
      <p class="text_white text_line-height-120 map__info-text">
        Рядом с Мегой Парнас! <br />
        От Меги предоставляем бесплатный трансфер до дилерского центра
      </p>
      <div class="map__info-group">
        <img class="map__info-icon" src="@/assets/icons/geo-marker-icon.svg" alt="geo" />
        <p class="text_white text_regular text_line-height-120">
          г. Санкт-Петербург, пос. Бугры,<br />
          д.Порошкино, ул. Торговая , зд. 22,<br />
          Пн-Вс с 8:00 до 22:00
        </p>
      </div>
      <button @click="openForm()" class="button button_blue-back map__button">Получить предложение</button>
    </div>
    <div class="map__overlay" v-show="!activeMap" @click="activeMap = true"></div>
    <yandex-map class="map__element" :coords="coords" :settings="settings" :controls="[]" :zoom="15">
      <ymap-marker marker-id="123" :coords="markerCoords" :icon="markerIcon" />
    </yandex-map>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { eventBus } from "../main.js";
export default {
  components: { yandexMap, ymapMarker },
  name: "Map",
  data() {
    return {
      coords: [60.08657, 30.370976],
      markerCoords: [60.086676, 30.38123],
      settings: {
        apiKey: "7fc89a61-90a9-4dd6-813a-6501e369d7af",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      markerIcon: {
        layout: "default#image",
        imageHref:
          "data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA0NCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0yMi4wMDAyIDBDMTAuMDE4NCAwIDAuMjcwNTA4IDkuNzQ3ODkgMC4yNzA1MDggMjEuNzI5NkMwLjI3MDUwOCAzNi41OTkzIDE5LjcxNjUgNTguNDI4OSAyMC41NDQ0IDU5LjM1MDlDMjEuMzIyMSA2MC4yMTcgMjIuNjc5OCA2MC4yMTU1IDIzLjQ1NjEgNTkuMzUwOUMyNC4yODQgNTguNDI4OSA0My43MyAzNi41OTkzIDQzLjczIDIxLjcyOTZDNDMuNzI5NyA5Ljc0Nzg5IDMzLjk4MiAwIDIyLjAwMDIgMFpNMjIuMDAwMiAzMi42NjI0QzE1Ljk3MTkgMzIuNjYyNCAxMS4wNjc2IDI3Ljc1OCAxMS4wNjc2IDIxLjcyOTZDMTEuMDY3NiAxNS43MDEzIDE1Ljk3MiAxMC43OTcgMjIuMDAwMiAxMC43OTdDMjguMDI4NSAxMC43OTcgMzIuOTMyOCAxNS43MDE0IDMyLjkzMjggMjEuNzI5N0MzMi45MzI4IDI3Ljc1ODEgMjguMDI4NSAzMi42NjI0IDIyLjAwMDIgMzIuNjYyNFoiIGZpbGw9IiNFRjQxMjMiLz4NCjwvc3ZnPg0K",
        imageSize: [43, 43],
      },
      getOffer: {
        success: false,
        title: "Получить предложение",
        text: "Оставьте заявку, и получите специальное предложение.",
      },
      activeMap: false,
    };
  },
  beforeMount() {
    if (window.innerWidth < 768) {
      this.coords = [60.089191, 30.377849];
    }
  },
  methods: {
    openForm() {
      eventBus.$emit("form", this.getOffer);
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  height: 600rem;
  position: relative;
  &__element {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8;
  }
  &__info {
    background: $blue;
    padding: 60rem 95rem;
    width: 630rem;
    position: absolute;
    top: 95rem;
    left: 175rem;
    z-index: 10;
    @include adaptive(desk-sm) {
      left: 100rem;
    }
    @include adaptive(tablet) {
      left: 40rem;
    }
    &-group {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 20rem;
      a {
        text-decoration: none;
      }
    }
    &-icon {
      position: absolute;
      top: 2px;
      left: -35px;
      width: 16px;
      height: 16px;
    }
    &-text {
      font-size: 22rem;
      margin-bottom: 20rem;
    }
  }
  &__button {
    min-width: 320rem;
  }
  @include adaptive(desk-sm) {
    &__info {
      left: 100rem;
    }
  }
  @include adaptive(tablet) {
    &__info {
      left: 40rem;
    }
  }
  @include adaptive(mob-big) {
    &__info {
      left: 45rem;
      width: 520rem;
      &-text {
        font-size: 18rem;
      }
    }
  }
  @include adaptive(mob) {
    height: 1000rem;
    &__info {
      padding: 40rem 30rem 40rem 75rem;
      left: 16rem;
      top: 40rem;
      width: calc(100% - 32rem);
    }
    &__button {
      width: calc(100% + 30px);
      max-width: 600rem;
      min-width: 0;
      position: relative;
      left: -30px;
      margin-top: 20rem;
    }
  }
}
</style>

<template>
  <div class="cars container" id="cars">
    <div class="cars__card" v-for="car in cars" :key="car.id">
      <div class="cars__more" @click="openModal(car)">
        <div class="cars__more-button">
          <div class="cars__more-icon-wrapper">
            <div class="cars__more-icon"></div>
          </div>
          <p class="cars__more-text text_white">
            Посмотреть подробную <br />
            комплектацию
          </p>
        </div>
        <div class="cars__more-overlay"></div>
        <img class="cars__img" :src="require(`@/assets/images/cars/${car.img}`)" :alt="car.shortTitle" />
      </div>
      <div class="cars__info">
        <p class="cars__title text_bold">{{ car.title }}</p>
        <p class="text_blue">{{ car.price }}</p>
        <ul class="cars__list offset_20">
          <li class="text_blue" v-for="item in car.info" :key="item.id">
            {{ item }}
          </li>
        </ul>
        <button @click="openForm(getOffer)" class="button button_wide cars__button">Получить предложение</button>
        <button @click="openForm(testDrive)" class="button button_blue-border button_wide offset_20 cars__button-2">
          Пройти тест-драйв
        </button>
      </div>
    </div>
    <Modal v-if="modal" @close="closeModal" :car="currentCar" />
  </div>
</template>

<script>
import Modal from "./Modal.vue";
import { eventBus } from "@/main.js";

export default {
  components: { Modal },
  name: "Cars",
  data() {
    return {
      cars: [
        {
          id: 1,
          title: "CRETA",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях", "Авто в наличии"],
          img: "Creta/Main.jpg",
          shortTitle: "Creta",
          colors: [
            {
              id: 1,
              item: "#C3C3C3",
              img: "Hyundai Creta Atlas White (SAW)",
              title: "Белый / Atlas White (SAW)",
            },
            {
              id: 2,
              item: "#2170AA",
              img: "Hyundai Creta Galaxy Blue (BE7)",
              title: "Синий / Galaxy Blue (BE7)",
            },
            {
              id: 3,
              item: "#A72225",
              img: "Hyundai Creta Dragon Red (WR7)",
              title: "Красный / Dragon Red (WR7)",
            },
            {
              id: 4,
              item: "#D3D8DF",
              img: "Hyundai Creta Sleek Silver (RHM)",
              title: "Серебристый / Sleek Silver (RHM)",
            },
            {
              id: 5,
              item: "#6A5B4E",
              img: "Hyundai Creta Liquid Bronze (SSP)",
              title: "Коричневый / Liquid Bronze (SSP)",
            },
            {
              id: 6,
              item: "#866641",
              img: "Hyundai Creta Cognanc Brown (WG7)",
              title: "Коричневый / Cognac Brown (WG7)",
            },
            {
              id: 7,
              item: "#828385",
              img: "Hyundai Creta Star Dust (V3G)",
              title: "Тёмно-серый / Star Dust (V3G)",
            },
            {
              id: 8,
              item: "#18161B",
              img: "Hyundai Creta Phantom Black (MZH)",
              title: "Черный / Phantom Black (MZH)",
            },
            {
              id: 9,
              item: "#861900",
              img: "Hyundai Creta Burnt Orange (R7R)",
              title: "Оранжевый / Burnt Orange (R7R)",
            },
          ],
          mods: [
            {
              title: "1.6л 6MT 2WD <br/> ",
              subtitle: "1.6 л, 123 л.c, Бензин",
            },
            {
              title: "1.6л 6MT 4WD <br/> ",
              subtitle: "1.6 л, 121 л.c, Бензин",
            },
            {
              title: "2.0л 6AT 2WD <br/> ",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
            {
              title: "1.6л 6MT 2WD <br/> ",
              subtitle: "1.6 л, 123 л.c, Бензин",
            },
            {
              title: "1.6л 6MT 4WD <br/> ",
              subtitle: "1.6 л, 121 л.c, Бензин",
            },
            {
              title: "2.0л 6AT 4WD <br/> ",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
          ],
          sets: [
            {
              title: "Prime <br/> ",
              subtitle: "Электроусилитель руля <br/> Маршрутный компьютер <br/> Обивка сидений тканью <br/> Кондиционер",
            },
            {
              title: "Classic <br/> ",
              subtitle:
                "Фары проекционного типа <br/> Обивка сидений тканью <br/> Кондиционер <br/> Bluetooth / громкая связь <br/> Hands Free",
            },
            {
              title: "Lifestyle <br/>",
              subtitle: "Фары проекционного типа <br/> Датчик дождя <br/> Подогрев руля <br/> Bluetooth / громкая связь <br/> Hands Free",
            },
          ],
        },
        {
          id: 2,
          title: "SOLARIS",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях", "Авто в наличии"],
          img: "Solaris/Main.jpg",
          shortTitle: "Solaris",
          colors: [
            {
              id: 1,
              item: "#C3C3C3",
              img: "Hyundai Solaris Crystal White (SAW)",
              title: "Белый / Atlas White (SAW)",
            },
            {
              id: 2,
              item: "#2170AA",
              img: "Hyundai Solaris Galaxy Blue (BE7)",
              title: "Синий / Galaxy Blue (BE7)",
            },
            {
              id: 3,
              item: "#A72225",
              img: "Hyundai Solaris Dragon Red (WR7)",
              title: "Красный / Dragon Red (WR7)",
            },
            {
              id: 4,
              item: "#D3D8DF",
              img: "Hyundai Solaris Sleek Silver (RHM)",
              title: "Серебристый / Sleek Silver (RHM)",
            },
            {
              id: 5,
              item: "#c6bea9",
              img: "Hyundai Solaris Liquid Sand (S5P)",
              title: "Коричневый / Liquid Sand (S5P)",
            },
            {
              id: 6,
              item: "#866641",
              img: "Hyundai Solaris Cognanc Brown (WG7)",
              title: "Коричневый / Cognac Brown (WG7)",
            },
            {
              id: 7,
              item: "#828385",
              img: "Hyundai Solaris Star Dust (V3G)",
              title: "Тёмно-серый / Star Dust (V3G)",
            },
            {
              id: 8,
              item: "#18161B",
              img: "Hyundai Solaris Phantom Black (MZH)",
              title: "Черный / Phantom Black (MZH)",
            },
          ],
          mods: [
            {
              title: "1.4л 6MT <br/> ",
              subtitle: "1.4 л, 100 л.c, Бензин",
            },
            {
              title: "1.6л 6MT <br/> о",
              subtitle: "1.6 л, 123 л.c, Бензин",
            },
            {
              title: "1.4л 6АТ <br/> ",
              subtitle: "1.4 л, 100 л.c, Бензин",
            },
            {
              title: "1.6л 6АT <br/> ",
              subtitle: "1.6 л, 123 л.c, Бензин",
            },
          ],
          sets: [
            {
              title: "Active <br/> ",
              subtitle: "Иммобилайзер <br/> Центральный замок <br/> Электроусилитель руля <br/> Воздушный фильтр салона",
            },
            {
              title: "Active Plus <br/> ",
              subtitle: "Электроусилитель руля <br/> Воздушный фильтр салона <br/> Кондиционер <br/> Шумоизоляция капота",
            },
            {
              title: 'Специальная серия <br class="not-mobile"/> "10 лет" <br class="mobile"/> ',
              subtitle: "Задние дисковые тормоза <br/> Шумоизоляция капота <br/> Подогрев передних сидений <br/> Дневные ходовые огни",
            },
            {
              title: "Comfort <br/> ",
              subtitle: "Подогрев руля <br/> Кондиционер <br/> Шумоизоляция капота <br/> Bluetooth / громкая связь <br/> Hands Free",
            },
          ],
        },
        {
          id: 3,
          title: "TUCSON",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях", "Авто в наличии"],
          img: "Tucson/Main.jpg",
          shortTitle: "Tucson",
          colors: [
            {
              id: 1,
              item: "#dcd5ca",
              img: "Tucson Silky Bronze (B6S)",
              title: "Бежевый / Silky Bronze (B6S)",
            },
            {
              id: 2,
              item: "#797b7e",
              img: "Tucson Shimmering Silver (R2T)",
              title: "Серебристый / Shimmering Silver (R2T)",
            },
            {
              id: 3,
              item: "#4e5457",
              img: "Tucson Amazon Grey (A5G)",
              title: "Серо-зеленый / Amazon Grey (A5G)",
            },
            {
              id: 4,
              item: "#020713",
              img: "Tucson Deep Sea (PS8)",
              title: "Синий / Deep Sea (PS8)",
            },
            {
              id: 5,
              item: "#222222",
              img: "Tucson Phantom Black (TCM)",
              title: "Черный / Phantom Black (TCM)",
            },
            {
              id: 6,
              item: "#b1b5b7",
              img: "Tucson White Cream (TW3)",
              title: "Белый / White Cream (TW3)",
            },
            {
              id: 7,
              item: "#691620",
              img: "Tucson Crimson Red (YP6)",
              title: "Красный / Crimson Red (YP6)",
            },
            {
              id: 8,
              item: "#353635",
              img: "Tucson Titan Gray (R4G)",
              title: "Темно-серый / Titan Gray (R4G)",
            },
          ],
          mods: [
            {
              title: "Smartstream G2.0 <br/> 6MT - 2WD <br/> ",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 6AT - 2WD <br/> ",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 6MT - 4WD <br/> ",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 6AT - 4WD <br/>",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
            {
              title: "Smartstream G2.5 <br/> GDi - 8AT - 4WD<br/> ",
              subtitle: "2.5 л, 190 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 8AT - 4WD <br/> ",
              subtitle: "2 л, 186 л.c, Дизель",
            },
          ],
          sets: [
            {
              title: "Family <br/> ",
              subtitle: "Подогрев руля <br/> Сетка для фиксации багажа <br/> Обивка сидений тканью <br/> Фары проекционного типа",
            },
            {
              title: "Lifestyle <br/>",
              subtitle: "Обивка сидений тканью <br/> Датчик дождя <br/> Камера заднего вида <br/> Подогрев передних сидений",
            },
            {
              title: "Prestige <br/> ",
              subtitle: "Камера заднего вида <br/> Подогрев передних сидений <br/> Подогрев задних сидений <br/> Рейлинги на крыше",
            },
            {
              title: "Visioner <br/>",
              subtitle: "Шторка багажника <br/> Панорамная крыша с люком <br/> Подогрев задних сидений <br/> Рейлинги на крыше",
            },
          ],
        },
        {
          id: 4,
          title: "ELANTRA",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях", "Авто в наличии"],
          img: "Elantra/Main.jpg",
          shortTitle: "Elantra",
          colors: [
            {
              id: 1,
              item: "#e7e7e7",
              img: "Elantra Polar White (WAW)",
              title: "Белый / Polar White (WAW)",
            },
            {
              id: 2,
              item: "#777d81",
              img: "Elantra Electric Shadow (USS)",
              title: "Серый / Electric Shadow (USS)",
            },
            {
              id: 3,
              item: "#af0101",
              img: "Elantra Fiery Red (PR2)",
              title: "Красный / Fiery Red (PR2)",
            },
            {
              id: 4,
              item: "#ad2f1b",
              img: "Elantra Lava Orange (YR2)",
              title: "Красно-оранжевый / Lava Orange (YR2)",
            },
            {
              id: 5,
              item: "#252726",
              img: "Elantra Phantom Black (NKA)",
              title: "Чёрный / Phantom Black (NKA)",
            },
            {
              id: 6,
              item: "#273452",
              img: "Elantra Intense Blue (YP5)",
              title: "Синий / Intense Blue (YP5)",
            },
            {
              id: 7,
              item: "#697073",
              img: "Elantra Fluid metal (M6T)",
              title: "Серый / Fluid metal (M6T)",
            },
            {
              id: 8,
              item: "#4e5457",
              img: "Elantra Amazon Grey (A5G)",
              title: "Серо-зеленый / Amazon Grey (A5G)",
            },
            {
              id: 9,
              item: "#979797",
              img: "Elantra Cyber Grey (C5G)",
              title: "Серый / Cyber Grey (C5G)",
            },
          ],
          mods: [
            {
              title: "1.6 MPI 6AT <br/> ",
              subtitle: "1.6 л, 128 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 6AT <br/> ",
              subtitle: "2 л, 1л.c, Бензин",
            },
          ],
          sets: [
            {
              title: "Base <br/>",
              subtitle: "Подогрев руля <br/> Дополнительный стоп-сигнал <br/> Электроусилитель руля <br/> Кондиционер",
            },
            {
              title: "Active <br/> ",
              subtitle: "Электроусилитель руля <br/> Датчик дождя <br/> Шумоизоляция капота <br/> Фары проекционного типа",
            },
            {
              title: "Way <br/> ",
              subtitle: "Шумоизоляция капота <br/> Подогрев передних сидений <br/> Камера заднего вида <br/> Фары проекционного типа",
            },
            {
              title: "Elegance <br/> ",
              subtitle: "Камера заднего вида <br/> Подогрев передних сидений <br/> Подогрев задних сидений <br/> Задние датчики парковки",
            },
          ],
        },
        {
          id: 5,
          title: "SANTA FE",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях", "Авто в наличии"],
          img: "Santafe/Main.jpg",
          shortTitle: "Santafe",
          colors: [
            {
              id: 1,
              item: "#5b5f5e",
              img: "Santa Fe Magnetic Force (M2F)",
              title: "Серо-зелёный / Magnetic Force (M2F)",
            },
            {
              id: 2,
              item: "#495350",
              img: "Santa Fe Rain Forest (R2F)",
              title: "Тёмно-зелёный / Rain Forest (R2F)",
            },
            {
              id: 3,
              item: "#f0efee",
              img: "Santa Fe White Cream (WW2)",
              title: "Белый / White Cream (WW2)",
            },
            {
              id: 4,
              item: "#000000",
              img: "Santa Fe Abyss Black (A2B)",
              title: "Чёрный / Abyss Black (A2B)",
            },
            {
              id: 5,
              item: "#b5b8ba",
              img: "Santa Fe Typhoon Silver (T2X)",
              title: "Серый / Typhoon Silver (T2X)",
            },
            {
              id: 6,
              item: "#463e3d",
              img: "Santa Fe Taiga Brown (RN7)",
              title: "Коричневый / Taiga Brown (RN7)",
            },
            {
              id: 7,
              item: "#c3c3c3",
              img: "Santa Fe Glacier White (W3A)",
              title: "Белый / Glacier White (W3A)",
            },
            {
              id: 8,
              item: "#405069",
              img: "Santa Fe Lagoon Blue (UE3)",
              title: "Синий / Lagoon Blue (UE3)",
            },
          ],
          mods: [
            {
              title: "2.5л - 6АТ <br/> ",
              subtitle: "2.5 л, 180 л.c, Бензин",
            },
            {
              title: "2.2л - 8DCT <br/> ",
              subtitle: "2 л, 149.6 л.c, Дизель",
            },
            {
              title: "3.5л - 8AT <br/> ",
              subtitle: "3.5 л, 249 л.c, Бензин",
            },
          ],
          sets: [
            {
              title: "Family <br/> ",
              subtitle: "Подогрев руля <br/> Сетка для фиксации багажа <br/> Обивка сидений тканью <br/> Датчик дождя",
            },
            {
              title: "Lifestyle <br/> ",
              subtitle: "Подогрев задних сидений <br/> Датчик дождя <br/> Камера заднего вида <br/> Подогрев передних сидений",
            },
            {
              title: "Prestige <br/> ",
              subtitle: "Шторка багажника <br/> Подогрев передних сидений <br/> Подогрев задних сидений <br/> Рейлинги на крыше",
            },
          ],
        },
        {
          id: 6,
          title: "SONATA",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях"],
          img: "Sonata/Main.jpg",
          shortTitle: "Sonata",
          colors: [
            {
              id: 1,
              item: "#000000",
              img: "Sonata Aurora Black (ABP)",
              title: "Черный / Sonata Aurora Black (ABP)",
            },
            {
              id: 2,
              item: "#eff0f2",
              img: "Sonata Clear White (UD)",
              title: "Белый / Clear White (UD)",
            },
            {
              id: 3,
              item: "#414548",
              img: "Sonata Nocturne Gray (T2G)",
              title: "Серый / Nocturne Gray (T2G)",
            },
            {
              id: 4,
              item: "#242c38",
              img: "Sonata Oxford Blue (XB2)",
              title: "Синий / Oxford Blue (XB2)",
            },
            {
              id: 5,
              item: "#a3161f",
              img: "Sonata Runway Red (CR5)",
              title: "Красный / Runway Red (CR5)",
            },
            {
              id: 6,
              item: "#797b7e",
              img: "Sonata Silky Silver (4SS)",
              title: "Серебристый / Silky Silver (4SS)",
            },
            {
              id: 7,
              item: "#f8f8f6",
              img: "Sonata Snow White (SWP)",
              title: "Белый / Snow White (SWP)",
            },
          ],
          mods: [
            {
              title: "2.0 MPI 6AT <br/> ",
              subtitle: "2 л, 1л.c, Бензин",
            },
            {
              title: "2.5 MPI - 6AT <br/> ",
              subtitle: "2.5 л, 180 л.c, Бензин",
            },
          ],
          sets: [
            {
              title: "Classic <br/> ",
              subtitle:
                "Электроусилитель руля <br/> Обивка сидений тканью <br/> Bluetooth / громкая связь Hands Free <br/> Подогрев передних сидений",
            },
            {
              title: "Classic (Winter) <br/> ",
              subtitle:
                "Bluetooth / громкая связь Hands Free <br/> Подогрев передних сидений <br/> Круиз-контроль <br/> Задние датчики парковки",
            },
            {
              title: "Comfort <br/> ",
              subtitle: "Подогрев руля <br/> Дополнительный стоп-сигнал <br/> Электроусилитель руля <br/> Датчик дождя",
            },
            {
              title: "Comfort (Winter) <br/>",
              subtitle: "Подогрев задних сидений <br/> Круиз-контроль <br/> Задние датчики парковки <br/> Передние датчики парковки",
            },
            {
              title: "Style <br/> ",
              subtitle: "Камера заднего вида <br/> Bluetooth / громкая связь Hands Free <br/> Подогрев передних сидений <br/> Датчик дождя",
            },
            {
              title: "Style (Winter) <br/> ",
              subtitle: "Задние датчики парковки <br/> Подогрев лобового стекла <br/> Передние датчики парковки <br/> Датчик света",
            },
            {
              title: "Business (Winter) <br/> ",
              subtitle: "Система кругового обзора <br/> Датчик света <br/> Подогрев лобового стекла <br/> Отделка руля кожей",
            },
          ],
        },
        {
          id: 7,
          title: "PALISADE",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях", "Авто в наличии"],
          img: "Palisade/Main.jpg",
          shortTitle: "Palisade",
          colors: [
            {
              id: 1,
              item: "#000000",
              img: "Palisade Abyss Black (A2B)",
              title: "Чёрный / Abyss Black (A2B)",
            },
            {
              id: 2,
              item: "#5d656a",
              img: "Palisade Lagoon Silver (S7S)",
              title: "Серый / Lagoon Silver (S7S)",
            },
            {
              id: 3,
              item: "#1c2e3e",
              img: "Palisade Moonlight Cloud (UB7)",
              title: "Темно-синий / Moonlight Cloud (UB7)",
            },
            {
              id: 4,
              item: "#380d15",
              img: "Palisade Sierra Burgundy (W7B)",
              title: "Темно-красный / Sierra Burgundy (W7B)",
            },
            {
              id: 5,
              item: "#494a4e",
              img: "Palisade Steel Graphite (P7V)",
              title: "Темно-серый / Steel Graphite (P7V)",
            },
            {
              id: 6,
              item: "#463e3d",
              img: "Palisade Taiga Brown (RN7)",
              title: "Коричневый / Taiga Brown (RN7)",
            },
            {
              id: 7,
              item: "#f8f8f6",
              img: "Palisade White Cream (WC9)",
              title: "Белый / White Cream (WC9)",
            },
          ],
          mods: [
            {
              title: "2.2 - 8AT CRDi <br/> ",
              subtitle: "2.2 л, 200 л.c, Дизель",
            },
            {
              title: "3.5 - 8АТ MPI <br/>",
              subtitle: "3.5 л, 249 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 6MT - 4WD <br/> ",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 6AT - 4WD <br/> ",
              subtitle: "2 л, 149.6 л.c, Бензин",
            },
            {
              title: "Smartstream G2.5 <br/> GDi - 8AT - 4WD<br/> ",
              subtitle: "2.5 л, 190 л.c, Бензин",
            },
            {
              title: "Smartstream G2.0 <br/> 8AT - 4WD <br/>",
              subtitle: "2 л, 186 л.c, Дизель",
            },
          ],
          sets: [
            {
              title: "Lifestyle <br/> ",
              subtitle:
                "Датчик дождя <br/> Сетка для фиксации багажа <br/> Bluetooth / громкая связь Hands Free <br/> Панель приборов Supervision",
            },
            {
              title: "Prestige <br/> ",
              subtitle: "Bluetooth / громкая связь Hands Free <br/> Рейлинги на крыше <br/> Шторка багажника <br/> Задние датчики парковки",
            },
            {
              title: "High-Tech <br/> ",
              subtitle: "Шторка багажника <br/> Задние датчики парковки <br/> Передние датчики парковки <br/> Датчик света",
            },
            {
              title: "Cosmos (8 мест) <br/> ",
              subtitle: "Передние датчики парковки <br/> Датчик света <br/> Отделка руля кожей <br/> Светодиодные фары",
            },
            {
              title: "Cosmos (7 мест) <br/> ",
              subtitle: "Система обзора слепых зон <br/> Пепельница <br/> Отделка руля кожей <br/> Светодиодные фары",
            },
          ],
        },
        {
          id: 8,
          title: "STARIA",
          price: "",
          info: ["Специальные условия на покупку", "Преимущества по программе трейд-ин ", "Кредит на лояльных условиях", "Авто в наличии"],
          img: "Staria/Main.jpg",
          shortTitle: "Staria",
          colors: [
            {
              id: 1,
              item: "#000000",
              img: "Staria black",
              title: "Чёрный / Abyss Black (A2B)",
            },
            {
              id: 2,
              item: "#797b7e",
              img: "Staria silver",
              title: "Серый / Shimmering Silver (R2T)",
            },
            {
              id: 3,
              item: "#2f3d59",
              img: "Staria blue",
              title: "Темно-синий / Moon Light Blue (UB5)",
            },
            {
              id: 4,
              item: "#25190c",
              img: "Staria brown",
              title: "Коричневый / Gaia Brown (D2S)",
            },
            {
              id: 5,
              item: "#dd9735",
              img: "Staria yellow",
              title: "Желтый / Dynamic yellow (NFA)",
            },
            {
              id: 6,
              item: "#e8e7e1",
              img: "Staria white",
              title: "Белый / Creamy White (YAC)",
            },
            {
              id: 7,
              item: "#5c635e",
              img: "Staria green",
              title: "Тёмно-зеленый / Olivine (X5R)",
            },
          ],
          mods: [
            {
              title: "2.2 CRDi - 8AT 2WD <br/> ",
              subtitle: "2.2 л, 177 л.c, Дизель",
            },
            {
              title: "2.2 CRDi - 8AT 4WD <br/>",
              subtitle: "2.2 л, 177 л.c, Дизель",
            },
            {
              title: "3.5 - 8AT 2WD <br/>",
              subtitle: "3.5 л, 249 л.c, Бензин",
            },
          ],
          sets: [
            {
              title: "Family <br/> ",
              subtitle:
                "Фронтальные подушки безопасности водителя и переднего пассажира <br/> Камера заднего вида <br/> Задние датчики парковки <br/> Боковые подушки безопасности водителя и переднего пассажира",
            },
            {
              title: "Lifestyle <br/> ",
              subtitle:
                "Система предупреждения водителей сзади при экстренном торможении (ESS) <br/> Регулировка передних ремней безопасности по высоте <br/> Электропривод и подогрев наружных зеркал <br/> Система управления стабилизацией (ABS, ESP, TCS, EBD, VSM)",
            },
            {
              title: "Prestige <br/> ",
              subtitle:
                'Пульт управления центральным замком в ключе + сигнализация <br/> Система мониторинга давления в шинах <br/> Датчик света <br/> Устройство вызова экстренных оперативных служб "Эра-Глонасс"',
            },
            {
              title: "High-Tech <br/> ",
              subtitle:
                'Электропривод складывания наружных зеркал <br/> Полноразмерное запасное колесо <br/> Регулировка рулевой колонки по высоте и вылету <br/> Программа "Помощь на дороге"',
            },
          ],
        },
      ],
      modal: false,
      currentCar: null,
      getOffer: {
        success: false,
        title: "Получить предложение",
        text: "Оставьте заявку, и получите специальное предложение.",
      },
      testDrive: {
        success: false,
        title: "Tест-драйв",
        text: "Оставьте заявку, и мы запишем Вас на тест-драйв в нашем центре.",
      },
    };
  },
  methods: {
    openForm(info) {
      eventBus.$emit("form", info);
    },
    openModal(car) {
      this.currentCar = car;
      this.modal = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.modal = false;
      document.body.classList.remove("modal-open");
    },
  },
};
</script>

<style lang="scss" scoped>
.cars {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 40px;
  margin-top: 120rem;
  margin-bottom: 80rem;

  &__card {
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: flex;
    max-height: 520rem;
    height: 100%;
    width: 100%;
    margin-bottom: 40rem;
    background: $lightGrey;
  }

  &__title {
    font-size: 30rem;
    line-height: 130%;
    color: $blue;
    text-transform: uppercase;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    width: 55%;
    padding: 40rem;
    transition: 0.3s;
    display: flex;
    flex-direction: column;

    &:hover {
      background: $blue;

      .cars__list li {
        color: $white;

        &::before {
          background: $white;
        }
      }

      .text_blue {
        color: $white;
      }

      .cars__title {
        color: $white;
      }

      .cars__button:hover {
        border: 2px solid $white;
      }

      .cars__button-2 {
        border: 2px solid $white;
        color: $white;

        &:hover {
          border: 2px solid transparent;
        }
      }
    }
  }

  &__more {
    position: relative;
    width: 45%;
    cursor: pointer;

    &:hover &-overlay {
      opacity: 0.5;
    }

    &-icon {
      width: 18px;
      height: 18px;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(316deg);
      position: relative;
      right: 2px;
      transition: 0.3s;

      &-wrapper {
        border: 2px solid $white;
        border-radius: 50%;
        width: 48px;
        min-width: 48px;
        height: 48px;
        min-height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s;
      }
    }

    &-button {
      position: absolute;
      bottom: 40rem;
      left: 40rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 1000;
    }

    &-text {
      font-size: 16rem;
      line-height: 130%;
      margin-left: 20rem;
      transition: 0.3s;
    }

    &-overlay {
      background: $blue;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.3s;
      z-index: 1;
    }
  }

  &__list li {
    margin-top: 10rem;
    display: grid;
    grid-template-columns: 0 1fr;
    gap: 20px;

    &::before {
      content: "";
      display: inline-block;
      background: $blue;
      border-radius: 50%;
      height: 10rem;
      width: 10rem;
      position: relative;
      top: 3px;
    }
  }

  &__button {
    margin-top: auto;
  }

  @include adaptive(mob-big) {
    margin-top: 60rem;
    column-gap: 30px;
    row-gap: 30px;
    &__card {
      height: 100%;
      max-height: 100%;
      flex-direction: column;
      margin-bottom: 0;
    }
    &__more {
      width: 100%;
    }
    &__info {
      width: 100%;
    }
    &__list {
      margin-bottom: 40rem;
    }
  }
  @include adaptive(mob) {
    grid-template-columns: 1fr;
    margin-top: 60rem;
    column-gap: 30px;
    row-gap: 30px;
    &__card {
      height: 100%;
      max-height: 100%;
      flex-direction: column;
    }
    &__more {
      width: 100%;
    }
    &__info {
      width: 100%;
    }
    &__list {
      margin-bottom: 40rem;
    }
  }
}
</style>

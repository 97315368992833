<template>
  <div class="header container">
    <img class="header__logo header__logo_mobile-big" src="@/assets/icons/logo.svg" alt="logo" />
    <div class="header__right">
      <a href="tel:+78123378888" class="text_blue text_bold text_middle header__phone">+7 (812) 337-88-88</a>
      <a class="anchor" href="#cars"><button class="button mobile-big">Выбрать авто</button></a>
      <img class="header__menu-icon mobile-big" src="@/assets/icons/menu.svg" alt="menu" @click="toggleMenu" />
      <ul class="header__buttons not-mobile">
        <a class="anchor" href="#cars"><li class="button">Выбрать авто</li></a>
        <li @click="openForm(testDrive)" class="button button_big">Спец предложения</li>
        <li @click="openForm(credit)" class="button button_small">Кредит</li>
        <li @click="openForm(service)" class="button button_big">Запись на сервис</li>
      </ul>
    </div>
    <div class="header__mobile">
      <div class="header__mobile-row">
        <img class="header__logo" src="@/assets/icons/logo.svg" alt="logo" />
        <img class="header__menu-icon mobile" src="@/assets/icons/menu.svg" alt="menu" @click="toggleMenu" />
      </div>
      <div class="header__mobile-row">
        <a href="tel:+78123378888" class="text_blue text_bold text_middle header__phone">+7 (812) 337-88-88</a>
        <button @click="openForm(buy)" class="button mobile">Купить авто</button>
      </div>
    </div>
    <transition name="slide-menu-right">
      <div v-show="openModal" class="menu__wrapper modal__wrapper">
        <div class="menu">
          <img class="header__logo header__logo_menu" src="@/assets/icons/logo.svg" alt="logo" />
          <a href="tel:+78123378888" class="text_blue text_bold text_middle offset_40">+7 (812) 337-88-88</a>
          <ul class="header__buttons">
            <li @click="openForm(testDrive)">Tест-драйв</li>
            <li @click="openForm(credit)">Кредит</li>
            <li @click="openForm(service)">Запись на сервис</li>
            <li @click="openForm(buy)">Купить авто</li>
          </ul>
          <img class="modal__close" src="@/assets/icons/close.svg" alt="X" @click="toggleMenu" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { eventBus } from "../main.js";
export default {
  name: "Header",
  data() {
    return {
      openModal: false,
      credit: {
        success: false,
        title: "Кредит",
        text: "Оставьте заявку, и мы расскажем все условия получения кредита.",
      },
      testDrive: {
        success: false,
        title: "Tест-драйв",
        text: "Оставьте заявку, и мы запишем Вас на тест-драйв в нашем центре.",
      },
      service: {
        success: false,
        title: "Запись на сервис",
        text: "Оставьте заявку, мы перезвоним и запишем Вас на сервис.",
      },
      buy: {
        success: false,
        title: "Купить авто",
        text: "Оставьте заявку, и получите специальное предложение.",
      },
    };
  },
  methods: {
    toggleMenu() {
      if (!this.openModal) {
        this.openModal = true;
        document.body.classList.add("modal-open");
      } else {
        this.openModal = false;
        document.body.classList.remove("modal-open");
      }
    },
    openForm(info) {
      eventBus.$emit("form", info);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 100rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__logo {
    height: 32rem;
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__phone {
    text-decoration: none;
  }
  &__buttons {
    display: flex;
    margin-left: 22rem;
    li {
      padding: 15rem;
      width: 170rem;
      color: $white;
      margin-left: 18rem;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.3s;
    }
  }
  .button {
    &_big {
      width: 210rem;
    }
    &_small {
      width: 110rem;
    }
  }
  &__mobile {
    display: none;
  }
  @include adaptive(tablet) {
    &__logo {
      height: 28rem;
    }
  }
  @include adaptive(mob-big) {
    &__logo {
      height: 28rem;
    }
    &__phone {
      margin-right: 30rem;
    }
    &__menu-icon {
      margin-left: 40rem;
    }
    &__buttons {
      flex-direction: column;
      margin: 55rem 0 0;
      width: 100%;
      li {
        width: 100%;
        margin: 20rem 0 0;
      }
    }
  }
  @include adaptive(mob) {
    justify-content: center;
    height: auto;
    padding: 30rem 16rem 20rem;
    &__logo {
      height: 18rem;
      &_mobile-big {
        display: none;
      }
      &_menu {
        height: auto;
        width: 100%;
        max-width: 325rem;
      }
    }
    &__phone {
      margin: 0;
    }
    &__mobile {
      display: block;
      width: 100%;
      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35rem;
        &:last-child {
          margin: 0;
        }
      }
    }
    &__right {
      display: none;
    }
  }
}
.menu {
  display: none;
  @include adaptive(mob-big) {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__wrapper {
      animation: slideLeft 0.3s ease-out forwards;
      background: rgba(255, 255, 255, 1) !important;
    }
  }
}
.slide-menu-right-leave-active,
.slide-menu-right-enter-active {
  transition: 0.5s;
}
.slide-menu-right-enter,
.slide-menu-right-leave-to {
  transform: translate(100%, 0);
}
</style>

<template>
  <div class="special container">
    <h1 class="special__title text_bold">Специальные предложения для клиентов</h1>
    <div class="special__card-wrapper">
      <div v-for="(special, i) in specials" :key="i" class="special__card" :class="{ special__card_wide: i === 1 }">
        <p class="special__card-title">{{ special.title }}</p>
        <ul class="special__list">
          <li class="special__list-item" v-for="item in special.items" :key="item.id">{{ item }}</li>
        </ul>
        <button @click="openForm()" class="button button_blue special__button">Получить предложение</button>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main.js";
export default {
  name: "Special",
  data() {
    return {
      specials: [
        {
          title: "Hyundai кредит",
          items: [
            "Низкие ставки на кредит",
            "Широкий выбор кредитных продуктов",
            "Первоначальный взнос от 0%",
            "Комфортный ежемесячный платеж",
            "Включение КАСКО и ТО в кредит",
            "Гибкие сроки кредита",
          ],
        },
        {
          title: "Дополнительные преимущества",
          items: [
            "Персональный подход к каждому клиенту",
            "Удобный паркинг и транспортная доступность",
            "Трансфер от ТРЦ МЕГА",
            "Комфортные клиентские зоны и кафе‎",
            "Проведение тест-драйва по уникальным маршрутам: от городских условий до бездорожья",
            "Гарантия на автомобили 3 года",
            "Программа помощи на дорогах",
            "Большой список страховых компаний",
            "Выкуп вашего автомобиля по рыночной стоимости",
            "Специальные условия на сервисное обслуживание при покупке авто",
          ],
        },
        {
          title: "Специльные условия для юридических лиц",
          items: [
            "Финансовый лизинг с выкупной стоимстью",
            "Подбор лизинговой программы согласно персональным требованиям клиента‎",
            "Широкий выбор дополнительного оборудования",
            "Возможность заключения договора на сервисное обслуживание",
            "Сопутствующие сервисы",
          ],
        },
      ],
      getOffer: {
        success: false,
        title: "Получить предложение",
        text: "Оставьте заявку, и получите специальное предложение.",
      },
    };
  },
  methods: {
    openForm() {
      eventBus.$emit("form", this.getOffer);
    },
  },
};
</script>

<style lang="scss" scoped>
.special {
  padding-top: 120rem;
  padding-bottom: 120rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-size: 42rem;
    text-transform: uppercase;
    line-height: 130%;
    margin-bottom: 60rem;
    text-align: center;
  }
  &__card {
    padding: 40rem 60rem;
    background: $lightGrey;
    margin-right: 40rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600rem;
    transition: 0.3s;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background: $blue;
      .special__card-title {
        color: $white;
      }
      .special__list-item {
        color: $white;
      }
      .special__button {
        background: $orange;
        &:hover {
          background: transparent;
          border: 2px solid $white;
        }
      }
    }
    &-title {
      font-size: 30rem;
      line-height: 130%;
      text-align: center;
      font-family: Hyundai-Bold;
      color: $blue;
      text-transform: uppercase;
      height: 80rem;
      margin-bottom: 40rem;
    }
    &-wrapper {
      display: flex;
    }
  }
  &__button {
    width: 310rem;
    margin: auto;
    margin-bottom: 0;
  }
  &__list {
    list-style-type: disc;
    list-style-position: outside;
    margin-bottom: 30rem;
    &-item {
      margin-bottom: 15rem;
    }
  }
  @include adaptive(mob-big) {
    padding-top: 80rem;
    padding-bottom: 80rem;
    &__card {
      max-width: 100%;
      margin-right: 0;
      &-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        row-gap: 30px;
        width: 100%;
      }
      &_wide {
        grid-area: 2 / 1 / 3 / 3;
        margin-right: 0;
      }
      &-title {
        height: 110rem;
      }
    }
  }
  @include adaptive(mob) {
    padding-top: 60rem;
    padding-bottom: 40rem;
    &__title {
      margin-bottom: 30rem;
    }
    &__card {
      max-width: 100%;
      margin-right: 0;
      &-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;
      }
      &-title {
        height: auto;
      }
    }
  }
}
</style>

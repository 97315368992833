<template>
  <div id="app">
    <Header />
    <MainBlock />
    <Advantages :data="advantages" :changeStyle="false" />
    <Cars />
    <Advantages :data="advantagesTwo" :changeStyle="true" />
    <Special />
    <Feedback />
    <Map />
    <FormModal v-if="openModal" :settings="settings" @close="openModal = false" />
    <TermsOfUse />
    <transition name="fade">
      <div class="arrow-up__wrapper" @click="scrollTop" v-show="showArrow">
        <div class="arrow-up"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import MainBlock from "./components/MainBlock.vue";
import Advantages from "./components/Advantages.vue";
import Cars from "./components/Cars/Cars.vue";
import Special from "./components/Special.vue";
import Feedback from "./components/Feedback.vue";
import Map from "./components/Map.vue";
import FormModal from "./components/FormModal.vue";
import TermsOfUse from "./components/TermsOfUse.vue";
import { eventBus } from "./main";
export default {
  name: "App",
  components: {
    Header,
    MainBlock,
    Advantages,
    Cars,
    Special,
    Feedback,
    Map,
    FormModal,
    TermsOfUse,
  },
  data() {
    return {
      advantages: [
        {
          img: "gift-icon.svg",
          text: "Специальные условия на покупку",
          alt: "gift",
        },
        {
          img: "autopark-icon.svg",
          text: "Большой парк автомобилей в наличии",
          alt: "autopark",
        },
        {
          img: "car-exchange-icon.svg",
          text: "Обмен вашего авто на выгодных условиях",
          alt: "car-exchange",
        },
        {
          img: "credit-icon.svg",
          text: "Быстрое и 100% одобрение кредита",
          alt: "credit",
        },
      ],
      advantagesTwo: [
        {
          img: "car-online-icon.svg",
          text: "Дистанционная оценка и выкуп вашего авто по рыночной стоимости",
          alt: "car online",
        },
        {
          img: "car-icon.svg",
          text: "Оформление сделки в течение 1 дня. Авто в наличии!",
          alt: "car",
        },
        {
          img: "insurence-icon.svg",
          text: "Широкий выбор страховых компаний и кредитных услуг",
          alt: "insurence",
        },
        {
          img: "auto-service-icon.svg",
          text: "Высокотехнологичный сервис. Только квалифицированные работники",
          alt: "auto-service",
        },
      ],
      settings: null,
      openModal: false,
      showArrow: false,
    };
  },
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
    handleArrow() {
      if (window.scrollY > 600) {
        this.showArrow = true;
      } else {
        this.showArrow = false;
      }
    },
  },
  created() {
    window.history.scrollRestoration = "manual";
    eventBus.$on("form", (data) => {
      this.settings = data;
      this.openModal = true;
      document.body.classList.add("modal-open");
    });
    window.addEventListener("scroll", this.handleArrow);
  },
};
</script>

<style lang="scss">
:root {
  font-size: 1px;
  color: $black;
  @include adaptive(desk-mid) {
    font-size: 0.9px;
  }
  @include adaptive(desk-sm) {
    font-size: 0.8px;
  }
  @include adaptive(tablet) {
    font-size: 0.7px;
  }
  @include adaptive(mob-big) {
    font-size: 0.8px;
  }
  @include adaptive(mob) {
    font-size: 0.7px;
  }
}

html {
  font-family: "Hyundai-Medium";
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  background: $white;
}

body.modal-open {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  font-size: 18rem;
}

ul {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}

.container {
  padding: 0 175rem;
  width: 100%;
  @include adaptive(desk-sm) {
    padding: 0 100rem;
  }
  @include adaptive(tablet) {
    padding: 0 40rem;
  }
  @include adaptive(mob-big) {
    padding: 0 45rem;
  }
  @include adaptive(mob) {
    padding: 0 16rem;
  }
}

a {
  color: $white;
  transition: 0.3s;
  &:hover {
    color: $orange;
  }
}

.mobile {
  display: none;
  &-big {
    display: none;
    @include adaptive(mob-big) {
      display: block !important;
    }
    @include adaptive(mob) {
      display: none !important;
    }
  }
  @include adaptive(mob) {
    display: block !important;
  }
  @include adaptive(mob-big) {
    display: none;
  }
}
.not-mobile {
  display: block;
  @include adaptive(mob) {
    display: none !important;
  }
  @include adaptive(mob-big) {
    display: none !important;
  }
}

.text {
  line-height: 100%;
  &_blue {
    color: $blue;
  }
  &_white {
    color: $white;
  }
  &_grey {
    color: $grey;
  }
  &_regular {
    font-family: "Hyundai-Regular";
  }
  &_bold {
    font-family: "Hyundai-Bold";
  }
  &_small {
    font-size: 14rem;
  }
  &_middle {
    font-size: 20rem;
    &-2 {
      font-size: 22rem;
    }
  }
  &_big {
    font-size: 32rem;
  }
  &_huge {
    font-size: 82rem;
  }
  &_line-height {
    &-120 {
      line-height: 120%;
    }
    &-130 {
      line-height: 130%;
    }
  }
  &_underline {
    text-decoration: underline;
  }
  &_uppercase {
    text-transform: uppercase;
  }
  &_center {
    text-align: center;
  }
  &_error {
    color: red;
    margin-top: 10rem;
    font-family: Hyundai-Regular;
  }
}
.anchor {
  text-decoration: none;
}
.button {
  background: $orange;
  color: $white;
  font-size: 18rem;
  font-family: "Hyundai-Medium";
  border: none;
  height: 54rem;
  padding: 0 24rem;
  transition: 0.3s;
  cursor: pointer;
  border: 2px solid transparent;
  text-decoration: none;
  &:active {
    background: $blue;
  }
  &_blue-back:active {
    background: transparent;
    border: 2px solid $white;
    color: $white;
  }
  &_blue {
    background: $blue;
    &:active {
      background: $orange;
    }
  }
  &_blue-border {
    border: 2px solid $blue;
    color: $blue;
    background: transparent;
    &:active {
      background: $orange;
      color: $white;
      border: 2px solid $orange;
    }
  }
  &_wide {
    width: 100%;
  }
}

@media (hover: hover) and (pointer: fine) {
  .button {
    &:hover {
      background: $blue;
    }
    &_blue {
      &:hover {
        background: $orange;
      }
      &-border:hover {
        background: $orange;
        color: $white;
        border: 2px solid $orange;
      }
      &-back:hover {
        background: transparent;
        border: 2px solid $white;
        color: $white;
      }
    }
  }
}

.offset {
  &_20 {
    margin-top: 20rem;
  }
  &_30 {
    margin-top: 30rem;
  }
  &_40 {
    margin-top: 40rem;
  }
  &_60 {
    margin-top: 60rem;
  }
}

.input {
  position: relative;
  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20rem;
    width: 15rem;
    height: 15rem;
    z-index: 10;
  }
  input {
    padding-top: 5px;
    color: #666666;
    background: $white;
    border: none;
    outline: none;
    height: 54rem;
    width: 100%;
    padding-left: 55rem;
    padding-right: 20rem;
    font-family: "Hyundai-Regular";
  }
}

.checkbox {
  display: flex;
}

input[type="checkbox"] {
  position: relative;
  width: 16px;
  height: 16px;
  border: 2px solid $white;
  appearance: none;
  outline: 0;
  cursor: pointer;
  margin-right: 20rem;
  transition: 0.3s;
  min-width: 16px;
  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    left: 2px;
    top: 2px;
    background: $white;
    opacity: 0;
  }
  &:checked {
    &::before {
      opacity: 1;
    }
  }
}

.modal {
  position: relative;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.15);
  animation: scaleIn 0.3s ease-out forwards;
  &__wrapper {
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    &_low {
      z-index: 9998;
    }
  }
  &__close {
    position: absolute;
    right: 42rem;
    top: 38rem;
    width: 40rem;
    height: 40rem;
    cursor: pointer;
    &:hover {
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
    }
  }
}

.select {
  border: 2px solid $blue;
  background: $white;
  width: 100%;
  padding: 20rem;
  color: $blue;
  font-family: Hyundai-Bold;
  font-size: 22rem;
  position: relative;
  &-arrow {
    font-size: 14px;
    position: absolute;
    right: 18px;
    border-right: 2px solid $blue;
    border-bottom: 2px solid $blue;
    width: 18px;
    height: 18px;
    top: 12px;
    transform: rotate(45deg);
  }
  &__block {
    position: absolute;
    z-index: 1000;
    background: $white;
    top: 53px;
    width: 100%;
  }
}

.arrow-up {
  &__wrapper {
    background: $blue;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: fixed;
    right: 16px;
    bottom: 110px;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  border-left: 2px solid $white;
  border-bottom: 2px solid $white;
  width: 25px;
  height: 25px;
  transform: rotate(135deg);
  cursor: pointer;
  position: relative;
  top: 5px;
  @include adaptive(mob-big) {
    &__wrapper {
      width: 64px;
      height: 64px;
      bottom: 24px;
      right: 24px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    }
    width: 25px;
    height: 25px;
  }
  @include adaptive(mob) {
    &__wrapper {
      width: 64px;
      height: 64px;
      bottom: 24px;
      right: 24px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }
    width: 25px;
    height: 25px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>

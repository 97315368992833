<template>
  <div class="terms container" id="terms">
    <button @click="toggleText" class="button terms__button">
      Политика обработки данных<span class="terms__button-arrow" :class="{ 'terms__button-arrow_active': showText }"></span>
    </button>
    <div class="terms__info offset_60" v-show="showText">
      <p class="text text_bold terms__title">Политика конфиденциальности</p>
      <p class="text text_regular offset_20">
        Компания ООО «Хендэ Мотор СНГ» очень ответственно подходит к вопросам конфиденциальности ваших данных.<br /><br />
        Как компания ООО «Хендэ Мотор СНГ» работает с личными данными.<br /><br />
        В ООО «Хендэ Мотор СНГ» очень ответственно подходят к сохранению конфиденциальности ваших данных. Политика конфиденциальности
        содержит разъяснения, касающиеся того, каким образом мы будем собирать и использовать информацию, которую вы нам предоставите. Мы
        можем периодически вносить изменения в данный документ; текущей версией является та, которая опубликована на этом сайте. Местоимение
        «мы» относится к компании ООО «Хендэ Мотор СНГ», если не указано иное.<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">1. Сбор ваших данных</p>
      <p class="text text_regular offset_20">
        Время от времени вы можете предоставлять свои личные данные (например, имя, адрес электронной почты и т. д.) для того, чтобы
        пользоваться услугами на нашем сайте. При сборе такой информации мы всегда будем использовать только безопасное соединение.<br /><br />
        Мы будем действовать в соответствии с применимым законодательством и будем предоставлять ссылку на Уведомление о защите данных
        каждый раз, когда мы будем запрашивать ваши личные данные, а также на форму Согласия об обработке персональных данных, когда,
        согласно законодательству, мы обязаны взять такое Согласие. Обрабатывать ваши личные данные мы будем только на основании вашего
        Согласия.<br /><br />
        Уведомление об ограниченной защите данных от компании ООО «Хендэ Мотор СНГ».<br /><br />
        Компания ООО «Хендэ Мотор СНГ» может использовать предоставленные вами данные в административных и маркетинговых целях, в интересах
        службы по работе с клиентами и для анализа ваших личных предпочтений. Мы можем хранить ваши данные в течение разумного периода
        времени для того, чтобы связываться с вами по поводу наших услуг. Информация о том, как мы используем файлы cookie и другие способы
        отслеживания в Интернете, содержится в разделе 2 настоящей Политики конфиденциальности.<br /><br />
        В случае размещения заявки на тест-драйв ваши данные могут быть переданы соответствующему дилеру (наименование и адрес дилера можно
        узнать на данном сайте), который может напрямую связаться с вами.<br /><br />
        Вы имеете право запросить копию ваших данных и исправить любые неточности. На нашем сайте вы можете узнать, как это сделать.<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">2. Как мы собираем данные о ваших посещениях нашего сайта</p>
      <p class="text text_regular offset_20">
        Когда вы посещаете страницы нашего сайта, мы загружаем на ваш компьютер так называемые «файлы cookie» (см. раздел 6). Мы также можем
        собирать данные, используя веб-маяки (также известные как «пустые GIF» или «веб-жучки» — см. раздел 7). Файлы cookie и веб-маяки
        обеспечивают нас неперсонифицированными статистическими данными о том, что посетители делают на нашем сайте. Например: время, в
        течение которого была просмотрена страница; обычные маршруты перемещения по сайту; данные о настройках экрана и другая информация
        общего характера. Мы можем также собирать данные о том, какие еще сайты вы посещаете после нашего. Мы используем полученные данные
        для того, чтобы повышать качество услуг на нашем сайте и делать его более полезным для вас.<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">3. Что мы будем делать с вашими личными данными</p>
      <p class="text text_regular offset_20">
        Мы будем использовать ваши данные только в тех целях, которые, в соответствии с разумными ожиданиями, должны представляться вам
        очевидными или которые будут объявлены при сборе информации. В случаях, когда будет необходимо ваше согласие, мы будем использовать
        ваши данные только после того, как вы его дадите. Мы не будем связываться с вами и передавать ваши данные нашим коммерческим
        партнерам в маркетинговых целях, если вы не согласитесь с такими действиями.<br /><br />
        Мы обязуемся относиться к вашим личным данным с должным вниманием и в соответствии с принципами защиты данных. Мы гарантируем защиту
        ваших персональных данных, в том числе с помощью соответствующих технических средств защиты.<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">4. Как получить доступ к своим личным данным</p>
      <p class="text text_regular offset_20">
        Вы можете направлять запросы менеджеру по защите и соответствию данных по адресу ООО «Хендэ Мотор СНГ» (123112, г. Москва, ул.
        Тестовская, д. 10). Если вы считаете, что какая-либо информация о вас недостоверна или используется ненадлежащим образом, или если
        вы хотите получить более подробную информацию, свяжитесь с нами по вышеупомянутому адресу.<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">5. Пользователи в возрасте до 18 лет включительно</p>
      <p class="text text_regular offset_20">
        Если вам 18 лет или менее, вы должны получить разрешение родителя или опекуна, перед тем как передать нам личные данные. Если мы
        выясним, что получили информацию от вас без надлежащего разрешения, мы оставляем за собой право отменить все транзакции и услуги, а
        также удалить все предоставленные вами личные данные. Вы сможете заново направить нам информацию после того, как получите
        необходимое разрешение.<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">6. Что такое cookie?</p>
      <p class="text text_regular offset_20">
        Cookie — это текстовый файл, который позволяет нашему серверу идентифицировать ваш компьютер (посредством IP-адреса). Информация о
        том, для чего мы это делаем, содержится в разделе 2. Хотя в некоторых случаях это представляется возможным, мы не используем
        IP-адреса для идентификации пользователей. Как правило, у вас есть возможность настроить свой компьютер так, чтобы либо принимать
        все файлы cookie, либо получать уведомления при их передаче, либо никогда не принимать такие файлы. Задать эти настройки можно
        непосредственно в браузере. В большинстве браузеров такая возможность доступна через меню (например, пункт «Сервис» в Internet
        Explorer или пункт «Инструменты» в Mozilla Firefox). Если у вас возникнут трудности с доступом к этим настройкам, обратитесь к
        справочному разделу в вашем браузере. Вы также можете найти более подробную информацию о файлах cookie и управлении ими на сайте
        www.allaboutcookies.org.<br /><br />
        Отказ от приема cookie может сделать невозможной нормальную работу сайта или даже лишить вас доступа к определенным разделам.<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">7. Что такое веб-маяк?</p>
      <p class="text text_regular offset_20">
        Некоторые наши веб-страницы могут содержать электронные изображения, так называемые веб-маяки (также известны как «пустые GIF»),
        которые позволяют нам посчитать число пользователей, посетивших данные страницы. Веб-маяки собирают только ограниченные данные,
        включающие номер cookie, время и дату просмотра страницы, а также описание страницы, на которой расположен маяк. Предоставляемые
        такими маяками данные не могут быть персонально идентифицированы, они используются только для отслеживания эффективности нашего
        сайта в целом или отдельных кампаний.<br /><br />
        Дополнительная информация о веб-маяках, их использовании и ваших правах доступна на сайте www.allaboutcookies.org/web-beacons/<br /><br />
        По любым вопросам, связанным с данной Политикой конфиденциальности или другими аспектами процессов хранения и обработки данных,
        обращайтесь на электронный адрес, указанный ниже, с пометкой «Для менеджера по защите и соответствию данных».<br /><br />
      </p>
      <p class="text text_bold text_big offset_40">8. Ссылки на другие сайты</p>
      <p class="text text_regular offset_20">
        Наши веб-сайты могут содержать ссылки на сторонние сайты. Ни компания ООО «Хендэ Мотор СНГ», ни связанные с ней компании не несут
        ответственности за соблюдение конфиденциальности на таких сайтах и их безопасность. Политика конфиденциальности распространяется
        только на этот веб-сайт.<br /><br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  data() {
    return {
      showText: false,
    };
  },
  methods: {
    toggleText() {
      this.showText = !this.showText;
      if (this.showText) {
        setTimeout(() => {
          document.querySelector("#terms").scrollIntoView();
        }, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60rem;
  padding-bottom: 80rem;
  &__title {
    font-size: 42rem;
  }
  &__button {
    display: flex;
    align-items: center;
    position: sticky;
    top: 20px;
    &-arrow {
      top: -3rem;
      left: 12rem;
      position: relative;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      width: 15rem;
      height: 15rem;
      display: inline-block;
      transform: rotate(315deg);
      margin: 0 5rem;
      transition: all 0.3s;
      &_active {
        top: 3rem;
        transform: rotate(135deg);
      }
    }
  }
  @include adaptive(mob) {
    padding-top: 20rem;
    padding-bottom: 30rem;
    &__title {
      font-size: 30rem;
    }
  }
}
</style>

<template>
  <div class="feedback container">
    <h1 class="feedback__title">Остались вопросы? Мы перезвоним!</h1>
    <p class="text_white text_center text_regular">
      Оставте заявку, и мы ответим на любые вопросы,<br />
      поможем с выбором или запишем на сервис
    </p>
    <div class="feedback__input">
      <div class="input feedback__input-mobile">
        <img src="@/assets/icons/phone-icon.svg" alt="phone" />
        <input
          class="feedback__input-inner"
          v-model="phone.value"
          v-mask="'+7 (###) ###-####'"
          type="tel"
          name="phone"
          placeholder="Ваш телефон"
          @change="validatePhone(phone)"
        />
      </div>
      <button @click="sendData" class="button feedback__input-button">Оставить заявку</button>
    </div>
    <p v-show="phone.error" class="text text_error">Неверно заполненное поле</p>
    <div class="checkbox offset_20">
      <input type="checkbox" name="terms" v-model="check" />
      <label for="terms" class="text_white text_regular text_line-height-120"
        >Отправляя заявку Вы соглашаетесь с условиями обработки <a href="#terms">персональных данных</a></label
      >
    </div>
  </div>
</template>

<script>
import sendForm from "@/api/index.js";
import { eventBus } from "../main.js";
export default {
  name: "Feedback",
  data() {
    return {
      phone: {
        value: "",
        error: false,
        validate: false,
      },
      check: true,
      isValidateInput: false,
    };
  },
  methods: {
    openForm() {
      const info = {
        success: true,
        title: "ОСТАЛИСЬ ВОПРОСЫ? МЫ ПЕРЕЗВОНИМ!",
        text: "Оставте заявку, и мы ответим на любые вопросы, поможем с выбором или запишем на сервис",
      };
      eventBus.$emit("form", info);
    },
    sendData() {
      this.checkValidate();
      if (this.isValidateInput) {
        const data = {
          name: "Перезвонить!",
          phone: this.phone.value,
          title: "Перезвонить!",
        };
        sendForm(data).then((response) => {
          if (response.status === 200) {
            this.openForm();
            this.input.phone.value = "";
            this.check = false;
          }
        });
      }
    },
    validatePhone(phone) {
      if (phone.value.length === 17) {
        phone.error = false;
        phone.validate = true;
      } else {
        phone.error = true;
        phone.validate = false;
      }
    },
    checkValidate() {
      if (this.phone.validate && this.check) {
        this.isValidateInput = true;
      } else {
        this.isValidateInput = false;
        this.phone.error = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("~@/assets/images/feedback.jpg") no-repeat;
  height: 600rem;
  background-size: cover;
  padding-top: 120rem;
  padding-bottom: 120rem;
  position: relative;
  &__title {
    font-size: 42rem;
    line-height: 130%;
    color: $white;
    font-family: Hyundai-Bold;
    text-transform: uppercase;
    margin-bottom: 10rem;
    text-align: center;
  }
  &__input {
    display: flex;
    margin-top: 50rem;
    &-inner {
      width: 360rem;
    }
    &-button {
      width: 216rem;
    }
  }
  @include adaptive(mob-big) {
    padding-top: 60rem;
    padding-bottom: 60rem;
    height: 100%;
    &__title {
      font-size: 30rem;
    }
  }
  @include adaptive(mob) {
    padding-top: 40rem;
    padding-bottom: 40rem;
    &__input {
      flex-direction: column;
      width: 100%;
      align-items: center;
      &-inner {
        width: 100%;
      }
      &-mobile {
        width: 100%;
        max-width: 600rem;
      }
      &-button {
        width: 100%;
        max-width: 600rem;
        margin-top: 20rem;
      }
    }
  }
}
</style>

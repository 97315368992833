<template>
  <div class="advantages container" :class="{ advantages_higher: changeStyle }">
    <div class="advantages__item" v-for="item in data" :key="item.id">
      <img
        class="advantages__item-img"
        :src="require(`@/assets/icons/${item.img}`)"
        :alt="item.alt"
        :class="{ 'advantages__item-img_larger': changeStyle }"
      />
      <p class="advantages__item-text">{{ item.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    changeStyle: Boolean,
  },
  name: "Advantages",
};
</script>

<style lang="scss" scoped>
.advantages {
  display: flex;
  align-items: center;
  background: $lightGrey;
  height: 130rem;
  &_higher {
    height: 275rem;
  }
  &__item {
    display: flex;
    align-items: center;
    margin-right: 60rem;
    width: 100%;
    &:last-child {
      margin-right: 0;
    }
    &-img {
      width: 60rem;
      height: 60rem;
      margin-right: 40rem;
      &_larger {
        width: 80rem;
        height: 80rem;
      }
    }
    &-text {
      font-size: 22rem;
      color: $grey;
      line-height: 120%;
      font-family: "Hyundai-Bold";
    }
  }
  @include adaptive(mob-big) {
    padding-top: 24rem;
    padding-bottom: 10rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    &__item {
      margin-bottom: 30rem;
      max-width: 380rem;
    }
  }
  @include adaptive(mob) {
    padding-top: 40rem;
    padding-bottom: 40rem;
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    &__item {
      margin-bottom: 30rem;
      max-width: 100%;
    }
  }
}
</style>

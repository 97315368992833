<template>
  <div class="main-block container">
    <div class="main-block__left">
      <p class="text text_white text_big text_regular main-block__title-top">Официальный дилерский центр</p>
      <p class="text text_white text_huge offset_40 text_bold main-block__title">
        Грандиозные<br />
        предложения!
      </p>
      <p class="text_white text_big text_line-height-120 offset_20 main-block__subtitle">
        Прозрачная сделка!<br />
        Кредитная ставка от 0% <br />Авто в наличии! Забирай сегодня!
      </p>
      <button @click="openForm(testDrive)" class="button main-block__left-button offset_60">Узнать все преимущества</button>
    </div>
    <div class="main-block__right">
      <p class="text_big text_line-height-120 text_white main-block__right-title">
        Оставьте заявку,<br class="mobile-big" />
        и получите специальное предложение.
      </p>
      <div class="main-block__right-input" style="margin-top: 30rem">
        <div class="input">
          <img src="@/assets/icons/phone-icon.svg" alt="phone" />
          <input
            type="tel"
            name="phone"
            v-model="input.phone.value"
            v-mask="'+7 (###) ###-####'"
            placeholder="Ваш телефон"
            @change="validatePhone(input.phone)"
          />
        </div>
        <p v-show="input.phone.error" class="text text_error">Неверно заполненное поле</p>
      </div>
      <div class="input main-block__right-input">
        <div class="input">
          <img src="@/assets/icons/user-icon.svg" alt="user" />
          <input name="user" type="text" v-model="input.name.value" placeholder="Имя" @change="validateName(input.name)" />
        </div>
        <p v-show="input.name.error" class="text text_error">Неверно заполненное поле</p>
      </div>
      <div class="checkbox offset_20">
        <input type="checkbox" name="terms" v-model="check" />
        <label for="terms" class="text_white text_regular text_line-height-120"
          >Отправляя заявку Вы соглашаетесь с условиями обработки <a href="#terms">персональных данных</a></label
        >
      </div>
      <button @click="sendData" class="button input main-block__right-button">Получить предложение</button>
    </div>
    <div class="main-block__footer">
      <p class="text text_regular text_big text_white text_uppercase main-block__footer-text">Модельный ряд</p>
      <a href="#cars" class="main-block__footer-arrow"></a>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../main.js";
import sendForm from "@/api/index.js";
export default {
  name: "MainBlock",
  data() {
    return {
      input: {
        name: {
          value: "",
          error: false,
          validate: false,
        },
        phone: {
          value: "",
          error: false,
          validate: false,
        },
      },
      check: true,
      isValidateInputs: false,
      getOffer: {
        success: true,
        title: "Получить предложение",
        text: "Оставьте заявку, и получите специальное предложение.",
      },
      testDrive: {
        success: false,
        title: "Tест-драйв",
        text: "Оставьте заявку, и мы запишем Вас на тест-драйв в нашем центре.",
      },
    };
  },
  methods: {
    sendData() {
      this.checkAllValidate();
      if (this.isValidateInputs) {
        const data = {
          name: this.input.name.value,
          phone: this.input.phone.value,
          title: "Главная форма",
        };
        sendForm(data).then((response) => {
          if (response.status === 200) {
            this.openForm(this.getOffer);
            this.input.name.value = "";
            this.input.phone.value = "";
            this.check = false;
          }
        });
      }
    },
    openForm(info) {
      eventBus.$emit("form", info);
    },
    validateName(name) {
      if (name.value.length > 1) {
        name.error = false;
        name.validate = true;
      } else {
        name.error = true;
        name.validate = false;
      }
    },
    validatePhone(phone) {
      if (phone.value.length === 17) {
        phone.error = false;
        phone.validate = true;
      } else {
        phone.error = true;
        phone.validate = false;
      }
    },
    checkAllValidate() {
      let countValidated = 0;
      for (const item in this.input) {
        if (this.input[item].validate) {
          countValidated++;
        } else {
          this.input[item].error = true;
        }
      }
      if (countValidated === Object.keys(this.input).length && this.check) {
        this.isValidateInputs = true;
      } else {
        this.isValidateInputs = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-block {
  background: url("~@/assets/images/main.jpg") no-repeat;
  height: 780rem;
  background-size: cover;
  padding-top: 80rem;
  padding-bottom: 80rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  &__left {
    &-button {
      width: 300rem;
    }
  }
  &__right {
    background: rgba(0, 52, 105, 0.6);
    border-radius: 2px;
    padding: 30rem 60rem;
    width: 540rem;
    height: 540rem;
    &-input {
      max-width: 362rem;
      margin-top: 20rem;
    }
    &-button {
      width: 272rem;
      margin-top: 30rem;
    }
  }
  &__footer {
    position: absolute;
    bottom: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    &-arrow {
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      width: 25px;
      height: 25px;
      transform: rotate(45deg);
      animation: upDown 0.6s infinite linear alternate;
      cursor: pointer;
    }
  }
  @include adaptive(mob-big) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &__title {
      font-size: 62rem;
      &-top {
        font-size: 24rem;
      }
    }
    &__subtitle {
      font-size: 32rem;
    }
    &__right {
      padding: 60rem;
      height: fit-content;
      width: 100%;
      &-title {
        font-size: 28rem;
      }
    }
    &__footer {
      left: 45rem;
      transform: translateX(0);
      &-text {
        font-size: 24rem;
      }
      &-arrow {
        width: 20px;
        height: 20px;
      }
    }
  }
  @include adaptive(mob) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-position: center;
    &__left {
      width: 100%;
      &-button {
        display: none;
      }
    }
    &__right {
      margin-left: 0;
      width: calc(100% + 32rem);
      padding: 40rem 16rem;
      margin-top: 60rem;
      &-input {
        max-width: 600rem;
      }
      &-button {
        width: 100%;
        max-width: 600rem;
      }
    }
    &__footer {
      position: relative;
      bottom: 0;
      left: 0;
      margin-top: 60rem;
    }
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0) rotate(45deg);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}
</style>

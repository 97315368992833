<template>
  <div class="modal__wrapper" @click.self="closeForm">
    <div class="modal">
      <img class="modal__close" src="@/assets/icons/close-white.svg" alt="X" @click="closeForm" />
      <div class="modal__form-wrapper" v-if="!success">
        <p class="text_white text_big text_center">{{ settings.text }}</p>
        <form>
          <div class="modal__form">
            <div class="modal__form-input">
              <div class="input">
                <img src="@/assets/icons/phone-icon.svg" alt="phone" />
                <input
                  type="tel"
                  name="phone"
                  v-model="input.phone.value"
                  v-mask="'+7 (###) ###-####'"
                  placeholder="Ваш телефон"
                  @change="validatePhone(input.phone)"
                />
              </div>
              <p v-show="input.phone.error" class="text text_error">Неверно заполненное поле</p>
            </div>
            <div class="modal__form-input">
              <div class="input">
                <img src="@/assets/icons/user-icon.svg" alt="user" />
                <input name="user" type="text" v-model="input.name.value" placeholder="Имя" @change="validateName(input.name)" />
              </div>
              <p v-show="input.name.error" class="text text_error">Неверно заполненное поле</p>
            </div>
          </div>
          <div class="checkbox offset_20">
            <input type="checkbox" name="terms" v-model="check" />
            <label for="terms" class="text_white text_regular text_line-height-120"
              >Отправляя заявку Вы соглашаетесь с условиями обработки <a href="#terms" @click="closeForm">персональных данных</a></label
            >
          </div>
        </form>
        <button @click="sendData" class="button button_blue-back modal__button">Отправить заявку</button>
      </div>
      <div class="success" v-else>
        <p class="success__text">Спасибо, ваша заявка отправлена.</p>
        <button @click="closeForm" class="button button_blue-back modal__button modal__button_success">ОК</button>
      </div>
    </div>
  </div>
</template>

<script>
import sendForm from "@/api/index.js";
export default {
  name: "FormModal",
  props: {
    settings: Object,
  },
  data() {
    return {
      input: {
        name: {
          value: "",
          error: false,
          validate: false,
        },
        phone: {
          value: "",
          error: false,
          validate: false,
        },
      },
      check: true,
      isValidateInputs: false,
      success: this.settings.success,
    };
  },
  methods: {
    closeForm() {
      this.$emit("close");
      if (document.getElementById("car-modal") === null) {
        document.body.classList.remove("modal-open");
      }
    },
    sendData() {
      this.checkAllValidate();
      if (this.isValidateInputs) {
        const data = {
          name: this.input.name.value,
          phone: this.input.phone.value,
          title: this.settings.title,
        };
        sendForm(data).then((response) => {
          if (response.status === 200) {
            this.success = true;
            this.input.name.value = "";
            this.input.phone.value = "";
            this.check = false;
          }
        });
      }
    },
    validateName(name) {
      if (name.value.length > 1) {
        name.error = false;
        name.validate = true;
      } else {
        name.error = true;
        name.validate = false;
      }
    },
    validatePhone(phone) {
      if (phone.value.length === 17) {
        phone.error = false;
        phone.validate = true;
      } else {
        phone.error = true;
        phone.validate = false;
      }
    },
    checkAllValidate() {
      let countValidated = 0;
      for (const item in this.input) {
        if (this.input[item].validate) {
          countValidated++;
        } else {
          this.input[item].error = true;
        }
      }
      if (countValidated === Object.keys(this.input).length && this.check) {
        this.isValidateInputs = true;
      } else {
        this.isValidateInputs = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  height: 510rem;
  width: 950rem;
  background: $blue;
  padding: 80rem 134rem;
  &__form {
    display: flex;
    column-gap: 40px;
    &-input {
      width: 100%;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
  }
  &__button {
    width: 300rem;
    margin: 0 auto;
    &_success {
      position: absolute;
      bottom: 0;
    }
  }
  @include adaptive(mob) {
    padding: 100rem 30rem 35rem;
    height: 620rem;
    &__form {
      flex-direction: column;
      margin-top: 30rem;
      &-input {
        margin-bottom: 20rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__button {
      margin-top: 20rem;
    }
  }
}
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  &__text {
    font-size: 24rem;
    text-align: center;
    color: $white;
  }
  @include adaptive(mob) {
    top: -30px;
  }
}
</style>

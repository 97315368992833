import Vue from "vue";
import App from "./App.vue";
import VueTheMask from "vue-the-mask";
import axios from "axios";
import VueAxios from "vue-axios";
import "./calltouch.js";
import "./google-tag-manager.js";

export const eventBus = new Vue();
Vue.use(VueTheMask, axios, VueAxios);
Vue.config.productionTip = false;
new Vue({
  render: (h) => h(App),
}).$mount("#app");
